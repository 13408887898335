import {GET_MEETINGS, CREATE_MEETING, CONFIRM_MEETING} from './types'


const initialState = {
    meetings: [],
    meeting: null
}

export const meetingReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_MEETINGS:
            return { ...state, meetings: action.payload }
        case CREATE_MEETING:
            return { ...state, meetings: state.meetings.concat([action.payload]) }
        case CONFIRM_MEETING:
            return { ...state, meeting: action.payload }
        default:
            return state
    }
}