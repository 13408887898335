import {
    STEP_UP,
    STEP_DOWN,
    STEP_RESET,
    STEP_SET,
    GET_CONDITIONS,
    CHANGE_CONDITION_ID,
    GET_TESTS,
    GET_CONDITION,
    SET_NOT_PASSED_TESTS,
    SET_TESTS_COUNT,
} from "./types";

const initialState = {
    step: 1,
    conditionId: null,
    conditions: [],
    condition: null,
    tests: [],
    testsCount: 0,
    notPassedTests: 0,
};

export const testReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CONDITIONS:
            return { ...state, conditions: action.payload };
        case CHANGE_CONDITION_ID:
            return { ...state, conditionId: action.payload };
        case STEP_UP:
            return { ...state, step: state.step + 1 };
        case STEP_DOWN:
            return { ...state, step: state.step - 1 };
        case STEP_RESET:
            return { ...state, step: 1 };
        case STEP_SET:
            return { ...state, step: action.payload };
        case GET_TESTS:
            return { ...state, tests: action.payload };
        case SET_NOT_PASSED_TESTS:
            return { ...state, notPassedTests: action.payload };
        case SET_TESTS_COUNT:
            return { ...state, testsCount: action.payload };
        case GET_CONDITION:
            return { ...state, condition: action.payload };

        default:
            return state;
    }
};
