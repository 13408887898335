import { GET_NOTES, CREATE_NOTE } from './types'


const initialState = {
    notes: []
}

export const noteReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_NOTES:
            return { ...state, notes: action.payload }
        case CREATE_NOTE:
            return { ...state, notes: state.notes.concat([action.payload]) }
        default:
            return state
    }
}