import fetch from '../fetch'
import { startLoading, finishLoading, showAlert } from '../actions/setAppActions'
import { GET_PUPILS } from '../types'


export function getPupils() {
    return async (dispatch, getState) => {
        try {
            dispatch(startLoading())
            const json = await fetch(`/api/pupil/`, { method: 'GET' }, getState, dispatch)

            dispatch({ type: GET_PUPILS, payload: json })
            dispatch(finishLoading())

        } catch (e) {
            dispatch(finishLoading())
            dispatch(showAlert({ type: 'error', text: e.message }))
        }
    }
}
