import fetch from '../fetch'
import { startLoading, finishLoading, showAlert } from '../actions/setAppActions'
import { GET_CONDITIONS, CHANGE_CONDITION_ID, GET_CONDITION } from '../types'


export function getConditionsINedded() {
    return (dispatch, getState) => {
        const state = getState().test

        if (!state.conditions.length) {
            return dispatch(getConditions())
        }
    }
}

export function getConditions() {
    return async (dispatch, getState) => {
        try {
            dispatch(startLoading())
            const json = await fetch('/api/conditions', { method: 'GET' }, getState, dispatch)

            dispatch({ type: GET_CONDITIONS, payload: json })
            dispatch(finishLoading())

        } catch (e) {
            dispatch(finishLoading())
            dispatch(showAlert({ type: 'error', text: e.message }))
        }
    }
}

export function getCondition(id) {
    return async (dispatch, getState) => {
        try {
            dispatch(startLoading())
            const json = await fetch(`/api/conditions/${id}`, { method: 'GET' }, getState, dispatch)

            dispatch({ type: GET_CONDITION, payload: json })
            dispatch(finishLoading())

        } catch (e) {
            dispatch(finishLoading())
            dispatch(showAlert({ type: 'error', text: e.message }))
        }
    }
}


export function changeConditionId(id) {
    return {
        type: CHANGE_CONDITION_ID,
        payload: id
    }
}