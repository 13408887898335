import { GET_PUPILS } from './types'


const initialState = {
    pupilsData: [],
}
export const pupilsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PUPILS:
            return { ...state, pupilsData: action.payload }
        default:
            return state
    }
}