import { GET_HISTORIES, GET_HISTORY } from './types'


const initialState = {
    histories: [],
    historyData: null,
}

export const historyReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_HISTORIES:
            return { ...state, histories: action.payload }
        case GET_HISTORY:
            return { ...state, historyData: action.payload }
        default:
            return state
    }
}