import React from 'react';

export const TestButton = ({ isActive, type, onClickHandler }) => {
    const classNames = [
        'answers-wrapper__btn',
        isActive ? 'answers-wrapper__btn_active' : '',
        type === 'back' ? 'answers-wrapper__btn_back' : ''
    ].join(' ');

    return <button onClick={onClickHandler} className={classNames} />;
};
