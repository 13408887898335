import { VK_API_URL } from "config";

const scriptId = "vk-openapi";

export const addVkScriptToPage = () => {
    const isScriptExist = document.getElementById(scriptId);

    if (!isScriptExist) {
        const script = document.createElement("script");
        script.src = VK_API_URL;
        script.id = scriptId;
        document.body.appendChild(script);
    }
};
