import {
    GET_CREATE_TEST_CONDITIONS,
    GET_CREATE_TEST_CONDITION_ID,
    GET_CREATE_TEST_SCHOOL_ID,
    GET_CREATE_TEST_CLASS_ID,
} from './types'


const initialState = {
    condtitions: [],
    conditionID: null,
    schoolId: null,
    classId: null,

    filters: {
        sex: '',
        years: '',
        category: '',
        condition: '',
        date: ''
    }
}


export const createTestReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CREATE_TEST_CONDITIONS:
            return { ...state, condtitions: action.payload }
        case GET_CREATE_TEST_CONDITION_ID:
            return { ...state, conditionId: action.payload }
        case GET_CREATE_TEST_SCHOOL_ID:
            return { ...state, schoolId: action.payload }
        case GET_CREATE_TEST_CLASS_ID:
            return { ...state, classId: action.payload }

        case 'CREATE_TEST/SET_FILTERS':
            return { ...state, filters: action.payload }
        default:
            return state
    }
}