import fetch from "../fetch";
import { startLoading, finishLoading, showAlert } from "../actions/setAppActions";
import {
    SET_NOT_PASSED_TESTS,
    GET_TESTS,
    STEP_UP,
    STEP_DOWN,
    STEP_SET,
    SET_TESTS_COUNT,
} from "../types";

export function getTestsBiPupilId(pupilId) {
    return async (dispatch, getState) => {
        try {
            dispatch(startLoading());
            const json = await fetch(
                `/api/tests/by-pupil-id${pupilId ? `?pupilId=${pupilId}` : ""}`,
                { method: "GET" },
                getState,
                dispatch
            );

            let quantity = 0;
            json.forEach((test) => {
                !test.solution && quantity++;
            });

            dispatch({ type: SET_NOT_PASSED_TESTS, payload: quantity });
            dispatch({ type: GET_TESTS, payload: json });
            dispatch(finishLoading());
        } catch (e) {
            dispatch(finishLoading());
            dispatch(showAlert({ type: "error", text: e.message }));
        }
    };
}

export function getTestsBiPsychId() {
    return async (dispatch, getState) => {
        try {
            dispatch(startLoading());
            const json = await fetch(
                `/api/tests/by-psych-id`,
                { method: "GET" },
                getState,
                dispatch
            );

            let quantity = 0;
            json.forEach((test) => {
                !test.solution && quantity++;
            });

            dispatch({ type: SET_NOT_PASSED_TESTS, payload: quantity });
            dispatch({ type: GET_TESTS, payload: json });
            dispatch(finishLoading());
        } catch (e) {
            dispatch(finishLoading());
            dispatch(showAlert({ type: "error", text: e.message }));
        }
    };
}

export function getTestsCountByPsych() {
    return async (dispatch, getState) => {
        try {
            dispatch(startLoading());
            const json = await fetch(
                `/api/tests/count-by-psych-id`,
                { method: "GET" },
                getState,
                dispatch
            );

            dispatch({ type: SET_TESTS_COUNT, payload: json.count });
            dispatch(finishLoading());
        } catch (e) {
            dispatch(finishLoading());
            dispatch(showAlert({ type: "error", text: e.message }));
        }
    };
}

export function stepUp() {
    return {
        type: STEP_UP,
    };
}

export function stepDown() {
    return {
        type: STEP_DOWN,
    };
}

export function setStep(step) {
    return {
        type: STEP_SET,
        payload: step,
    };
}
