import fetch from '../fetch'
import { startLoading, finishLoading, showAlert } from '../actions/setAppActions'
import { GET_HISTORIES, GET_HISTORY } from '../types'


export function getHistories() {
    return async (dispatch, getState) => {

        try {
            dispatch(startLoading())
            const json = await fetch('/api/histories', { method: 'GET' }, getState, dispatch)

            dispatch({ type: GET_HISTORIES, payload: json })
            dispatch(finishLoading())

        } catch (e) {
            dispatch(finishLoading())
            dispatch(showAlert({ type: 'error', text: e.message }))
        }
    }
}

export function getHistory(id) {
    return async (dispatch, getState) => {
        try {
            dispatch(startLoading())
            const json = await fetch(`/api/histories/${id}`, { method: 'GET' }, getState, dispatch)

            dispatch({ type: GET_HISTORY, payload: json })
            dispatch(finishLoading())

        } catch (e) {
            console.log(e)
            dispatch(finishLoading())
            dispatch(showAlert({ type: 'error', text: e.message }))
        }
    }
}
