import { GET_PUPIL, GET_CATEGORIES } from './types'


const initialState = {
    categories: [],
    pupilData: null,
}

export const pupilReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PUPIL:
            return { ...state, pupilData: action.payload }
        case GET_CATEGORIES:
            return { ...state, categories: action.payload }
        default:
            return state
    }
}