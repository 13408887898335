import { GET_LAST_ALARM, CREATE_ALARM, GET_ALARMS, READ_ALARMS } from './types'


const initialState = {
    alarms: [],
    lastAlarm: null,
}

export const alarmReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_LAST_ALARM:
            return { ...state, lastAlarm: action.payload }
        case CREATE_ALARM:
            return { ...state, lastAlarm: action.payload }
        case GET_ALARMS:
            return { ...state, alarms: action.payload }
        case READ_ALARMS:
            return { ...state, alarms: action.payload }
        default:
            return state
    }
}